import * as React from "react";
import AssetPage from "../../components/asset-page";

const DogecoinPage = () => (
  <AssetPage
    asset="Dogecoin"
    ticker="DOGE"
    description={[
      "Dogecoin, often recognized by its emblematic Shiba Inu from the popular \"Doge\" meme, began its journey as a playful endeavor in December 2013. Created by Billy Markus and Jackson Palmer, it was initially conceptualized as a light-hearted alternative to Bitcoin, aiming to reach a broader audience and steer clear of the controversial narratives surrounding other cryptocurrencies of the time. However, what started as a jest quickly gained traction and evolved into a legitimate digital currency, supported by a passionate and dedicated community.",
      "Dogecoin has transformed from its meme-inspired origins to become a notable player in the world of cryptocurrencies. Its unique features, combined with a vibrant and active community, ensure that it remains more than just a fleeting internet sensation. It serves as a testament to the unpredictable and ever-evolving nature of the digital currency landscape.",
    ]}
    hideFeatureImage
    features={[
      {
        title: "Swift Transaction Times",
        text: "One of Dogecoin's standout features is its quick transaction speeds. Designed with a block time of just one minute (compared to Bitcoin's ten), Dogecoin facilitates faster transaction confirmations. This speed has been instrumental in its adoption for small transactions or as a tipping currency online.",
      },
      {
        title: "Low Transaction Fees",
        text: "Dogecoin offers minimal transaction fees, making it a favored choice for micro-transactions and online tipping. Its affordability ensures that users can make frequent and small transactions without the burden of hefty fees, which is especially useful for those wanting to experiment with cryptocurrency without significant financial commitments.",
      },
      {
        title: "Infinite Supply",
        text: "Unlike Bitcoin, which has a capped supply of 21 million coins, Dogecoin has no maximum supply limit. This feature was initially introduced to ensure a consistent reduction in the rate of its inflation. While this infinite supply has its critics, it also means that miners are consistently rewarded for processing transactions, potentially ensuring the long-term security and viability of the network.",
      },
    ]}
  />
);

export default DogecoinPage;
